import React, { ReactNode, useEffect, useState } from "react";
import { Box, Button, Dialog, Grid } from "@mui/material";
import LBVNav from "../../component/Navbar";
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel";
import { isPhone } from "../../helper/constant";
import { useWindowSize } from "../../helper/windowResize";

import moment from "moment";

import { FaArrowUpFromBracket } from "react-icons/fa6";
import LBVCalendar from "../../component/booking/form/Calendar";
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';

import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import ShareLinkComponent from "../../component/share/ShareLinkComponent";
import CardSlider from "../../component/card/CardSlider";
import { format, addDays, setDate } from "date-fns";
import { currencyFormat } from "../../helper/numberFormat";
import { getProperty } from "../../services/propertiesService";
import { useParams } from "react-router-dom";
import DialogHeader from "../../component/dialog/DialogHeader";
import { Card } from "shards-react";
import { LBVInput } from "../../component/input/LBVInput";
import RoomTable from "../../component/roomtable/RoomTable";
import CommentComponent from "../../component/comment/Comment";
import LBVDateInput from "../../component/input/LBVDateInput";
import LBVNumberInput from "../../component/input/LBVCounterNumber";
import LBVLoading from "../../component/loading/LBVLoading";
import RoomDetail from "./RoomDetail";
import { properties } from "../../component/slider/SlideButton";
import LBVGallery from "../../component/Lightbox";

import ListImage from "../../component/ListImage";

function Place() {

    const placeDetailInfo = {
        _id: null,
        propertyImage: [],
        name: "",
        location: "",
        type: "",
        key: "",
        specifications: {
            breakfast: true,
            freeWifi: true,
            swimmingPool: true,
            privatePool: true
        },
        roomType: [
            {
                _id: null,
                room: 4,
                bed: null,
                propertyImage: [],
                name: "4 Bedrooms",
            }, 
            {
                _id: null,
                room: 6,
                bed: null,
                propertyImage: [],
                name: "6 Bedrooms",
            }
        ],
        description: "Located on Jl Batu Jangkih Sepi, a serene retreat awaits. This exquisite villa offers a secluded sanctuary with its four bedrooms, each adorned with king-size beds for a luxurious stay. What sets this Villa apart is its breathtaking panoramic views. From the villa, you can indulge in the beauty of both the meticulously landscaped garden and the vast expanse of the ocean. This harmonious blend of nature’s wonders creates an atmosphere of tranquility and awe.",
        feature: [],
        price: 5300000,
    }

    const priceModelInfo = [
        {
            date: null,
            day: null,
            price: 0
        },
        {
            date: ['16-08-2024', '17-08-2024', '18-08-2024'],
            day: null,
            price: 0
        },
        {
            date: null,
            day: ['Saturday'],
            price: 0
        },
    ]


    const [ windowSize, setWindowSize ] = useState(useWindowSize())
    const [ form, setForm ] = useState({
        startDate: null,
        endDate: null,
        dates: [],
        placeId: null, // placeDetail._id
        roomType: null, // roomType._id
    })

    const [ showImageModel, setShowImageModel ] = useState(false)
    const [ placeDetail, setPlaceDetail ] = useState(null)
    const [ propertyImages, setPropertyImages] = useState([])
    const [ priceModel, setPriceModel ] = useState(priceModelInfo)
    const [ showRoomDetail, setShowRoomDetail] = useState({
        open: false,
        data: null
    })
    const [ openDetailImage, setOpenImageDetail ] = useState(null)

    const { id } = useParams();

    useEffect(() => {
        loadInfoDetail()
    }, [])

    const loadInfoDetail = async () => {
        try {
            let { data, error } = await getProperty(id)
            if (error) { }
            if (data) { 
                setPlaceDetail({
                    ...data,
                    roomType: data.rooms
                })
                setPropertyImages(data.propertyImage)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setFormInformation(form)
    }, [priceModel])

    const setFormInformation = (data : any) => {
        // calculate price
        // date
        if (data.startDate && data.endDate) {
            let datesArray = getDates(data.startDate, data.endDate)
            data.dates = datesArray
            let priceValue = calculatePrice(data)
            data.totalPrice = priceValue
        }
        setForm(prev => ({
            ...prev,
            ...data
        }))
    }

    const getDates = (startDate: any, endDate: any) => {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= endDate) {
            dateArray.push(format(currentDate, "yyyy-MM-dd"));
            currentDate = addDays(currentDate, 1);
        }
        return dateArray;
    }

    const calculatePrice = (data : any) => {
        let priceValue = 0
        for (let d = 0; d < data.dates.length; d ++) {
            let price = 0
            for (let i = 0; i < priceModel.length; i ++) {
                if (!priceModel[i].date && !priceModel[i].day) {
                    price = priceModel[i].price
                }
                if (priceModel[i].day && priceModel[i].day!.indexOf(moment(data.dates[d]).format('dddd')) >= 0) {
                    price = priceModel[i].price
                }
                if (priceModel[i].date && priceModel[i].date!.indexOf(moment(data.dates[d]).format('YYYY-MM-DD')) >= 0) {
                    price = priceModel[i].price
                }
            }
            priceValue += price
        }
        return priceValue
    }

    useEffect(() => {
    }, [form])

    return (
        <>
            <Grid container xs={12} md={12} justifyContent={"left"} alignItems={'top'}>
                <LBVNav />
                {
                    !isPhone() ?
                    <DesktopView placeDetail={placeDetail} priceModel={priceModel} form={form} 
                        setForm={(data: any) => setFormInformation(data)}
                        setPriceModel={(data: any) => setPriceModel(data)}
                        setShowImageModel={(e: any) => setShowImageModel(e)}
                        setShowRoomDetail={(data: any) => setShowRoomDetail(data)}
                    />
                    :
                    <PhoneView placeDetail={placeDetail} priceModel={priceModel} form={form} 
                        setForm={(data: any) => setFormInformation(data)}
                        setPriceModel={(data: any) => setPriceModel(data)}
                        setShowImageModel={(e: any) => setShowImageModel(e)}
                        setShowRoomDetail={(data: any) => setShowRoomDetail(data)}
                    />
                }
            </Grid>
            {/* <LBVGallery
                open={openDetailImage}
                setOpen={() => { setOpenImageDetail(null) }}
                images={[openDetailImage]}
            /> */}
            <Dialog
                fullWidth={true}
                fullScreen={true}
                open={showRoomDetail.open || showImageModel}
                onClose={() => {
                    if (showRoomDetail.open) {
                        setShowRoomDetail({
                            open: false,
                            data: null
                        })
                    } else { setShowImageModel(false) }
                }}
            >
                {
                    (showRoomDetail.open && showRoomDetail.data) &&
                    <Grid p={2}>
                        <DialogHeader title={showRoomDetail.data['name']} closeDialog={() => {
                            setShowRoomDetail({
                                open: false,
                                data: null
                            })
                        }} />
                        <RoomDetail data={showRoomDetail.data} />
                    </Grid>
                }
                {
                    showImageModel && 
                    <ListImage
                        showImageModel={showImageModel}
                        setShowImageModel={setShowImageModel}
                        setOpenImageDetail={setOpenImageDetail}
                        propertyImages={propertyImages}
                    />
                }
            </Dialog>
        </>
    )
}

function PhoneView (
    props: {
        placeDetail: any,
        form: any,
        priceModel: any[],
        setForm: (data: any) => void,
        setPriceModel: (data: any) => void,
        setShowImageModel: (e: any) => void,
        setShowRoomDetail: (e: any) => void
    }
) {

    const { placeDetail, form, setForm, priceModel, setPriceModel, setShowImageModel, setShowRoomDetail } = props
    const [ selectedRoom, setSelectedRoom ] = useState("")
    const [ showDetail, setShowDetail ] = useState(false)
    const [ showShare, setShowShare ] = useState(false)

    const [ dateInfo, setDateInfo ] = useState(null)

    const [ guestInfo, setGuestInfo ] = useState({
        adult: 1,
        kids: 0
    })

    const [ roomInfo, setRoomInfo ] = useState(1)

    useEffect(() => {
        if (placeDetail) { setSelectedRoom(placeDetail.roomType[0]._id) }
    }, [placeDetail])

    useEffect(() => {
        console.log('here dateInfo', dateInfo)
    }, [dateInfo])

    useEffect(() => {
        if ((placeDetail && placeDetail.rooms) && selectedRoom) {
            let rooms = null
            for (let i = 0; i < placeDetail.rooms.length; i ++) {
                let room =  placeDetail.rooms[i]
                if (selectedRoom == room._id) {
                    rooms = room;
                    break;
                }
            }
            console.log('here rooms info', rooms)
            let priceList = rooms.priceList ? [
                {
                    date: null,
                    day: null,
                    price: rooms.price
                },
                ...rooms.priceList,
            ] : null
            setPriceModel(priceList)
        }
        setForm( (prev : any) => ({
            ...prev
        }))
    }, [selectedRoom])

    return (
        <>
            {
                placeDetail ?
                <>
                    <Grid container xs={12}>
                        <Grid item xs={12} pb={1} textAlign={"right"}>
                            <div style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                setShowShare(true)
                            }}>
                                <FaArrowUpFromBracket/>
                                <LBVLabel style={{
                                    marginLeft: 5
                                }}>Share</LBVLabel>
                            </div>
                            {
                                showShare &&
                                <Grid p={2} sx={{
                                    zIndex: 9999,
                                    position: 'absolute',
                                    marginTop: -2,
                                    right: -2
                                }}>
                                    <div style={{
                                        position: 'fixed',
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0
                                    }} onClick={() => {
                                        setShowShare((prev) => false)
                                    }}/>
                                    <ShareLinkComponent shareUrl={`https://staging-lbv.wuebuild.com/${placeDetail.type}/${placeDetail.key}`}/>
                                </Grid>
                            }
                        </Grid>
                        <Grid xs={12}>
                            <Slide
                                {...properties}
                                indicators={false}
                                duration={3000}
                                autoplay={true}
                                transitionDuration={200}
                            >
                                {
                                    placeDetail.propertyImage.map( (value: any) => {
                                        return (
                                            <img src={value} width={'100%'} style={{
                                                maxHeight: window.innerWidth >= 444 ? window.innerWidth/1.78 : window.innerWidth / 1.78
                                            }}/>
                                        )
                                    })
                                }
                            </Slide>
                        </Grid>
                        <Grid container xs={12} textAlign={"right"}>
                            <Grid xs={6} textAlign={"right"} pr={2}></Grid>
                            <Grid xs={6} textAlign={"right"}>
                                <LBVLabel style={{
                                    cursor: 'pointer'
                                }}><span onClick={() => { setShowImageModel(true) } }>View All Photos</span></LBVLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container mt={1}>
                        <Grid item xs={12}>
                            <LBVTitleLabel style={{
                                fontSize: 24,
                                fontWeight: 500
                            }}>{placeDetail.name}</LBVTitleLabel>
                        </Grid>
                    </Grid>
                    {/* Description */}
                    <Grid container>
                        <LBVLabel>
                            <div dangerouslySetInnerHTML={{__html: placeDetail.description}}/>
                        </LBVLabel>
                    </Grid>
                    <Grid container mt={2} mb={2}>
                        <Grid item xs={12}>
                            <LBVTitleLabel>
                                Select Check-in Date
                            </LBVTitleLabel>
                        </Grid>
                        <Grid item container xs={12} lg={3} p={1}>
                            <LBVDateInput isOpenDetail={showDetail} setDateInfo={(e:any) => {
                                setDateInfo(e)
                            }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <LBVTitleLabel>
                                Guest Information
                            </LBVTitleLabel>
                        </Grid>
                        <Grid item container xs={6} lg={1} p={1}>
                            <Grid xs={12}><LBVLabel>Adult</LBVLabel></Grid>
                            <LBVNumberInput
                                value={guestInfo.adult}
                                maxNumber={null}
                                onChange={(e:any) => { 
                                    setGuestInfo(prev => ({
                                        ...prev,
                                        adult:  e
                                    }))
                                }}
                                isFlex={true}
                            />
                        </Grid>
                        <Grid item container xs={6} lg={1} p={1}>
                            <Grid xs={12}><LBVLabel>Children</LBVLabel></Grid>
                            <LBVNumberInput
                                value={guestInfo.kids}
                                maxNumber={null}
                                allowZero={true}
                                onChange={(e:any) => { 
                                    setGuestInfo(prev => ({
                                        ...prev,
                                        kids:  e
                                    }))
                                }}
                                isFlex={true}
                            />
                        </Grid>
                        <Grid item container xs={6} lg={1} p={1}>
                            <Grid xs={12}><LBVLabel>Rooms</LBVLabel></Grid>
                            <LBVNumberInput
                                value={roomInfo}
                                maxNumber={null}
                                onChange={(e:any) => { 
                                    setRoomInfo(e)
                                }}
                                isFlex={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <RoomTable data={placeDetail} 
                            formInfo={{
                                dateInfo,
                                ...guestInfo,
                                rooms: roomInfo
                            }}
                            setShowRoomDetail={(e: any) => { setShowRoomDetail({
                                open: true,
                                data: e
                            }) }}
                            showCalendar={() => { setShowDetail(true) }}/>
                    </Grid>
                    {
                        placeDetail.houseRules &&
                        <Grid container mt={2}>
                            <LBVTitleLabel>
                                Things to know
                            </LBVTitleLabel>
                            <Grid container>
                                <LBVLabel>
                                    <div dangerouslySetInnerHTML={{__html: placeDetail.houseRules}}/>
                                </LBVLabel>
                            </Grid>
                        </Grid>
                    }
                    <Grid container mt={2}>
                        <CommentComponent propertyId={placeDetail._id} />
                    </Grid>
                    {
                        placeDetail.mapInfo &&
                        <Grid container xs={12} mt={2}>
                            <LBVTitleLabel style={{fontSize: '24px !important'}}>
                                Location
                            </LBVTitleLabel>
                            <Grid container>
                                <iframe src={placeDetail.mapInfo} 
                                    width="300" height="300" 
                                    style={{ border: 0 }}
                                    allowFullScreen={false}
                                    loading="lazy" 
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </Grid>
                        </Grid>
                    }
                    {/* set fixed bar */}
                    {/* <Grid sx={{
                        position: 'fixed',
                        bottom: 0,
                        backgroundColor: 'white',
                        left: 0,
                        right: 0,
                        minHeight: 60
                    }}>
                        <Grid container p={2} pt={1} pb={1} justifyItems={"center"} alignItems={"center"}>
                            <Grid item container xs>
                                <Grid item xs={12} p={0} m={0}>
                                    <div><LBVLabel subtitle>Price information</LBVLabel></div>
                                    <div><LBVLabel bold style={{fontSize: 16}}>Price</LBVLabel></div>
                                </Grid>
                                <Grid item xs={12} p={0} m={0}>
                                    <LBVLabel subtitle>Booking Date</LBVLabel>
                                </Grid>                                
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" style={{
                                    width: '100%',
                                    minWidth: 60,
                                    minHeight: 30,
                                    borderRadius: 20
                                }}
                                    onClick={() => {
                                        console.log('hello world')
                                    }}
                                >
                                    <LBVLabel inheritColor>Book</LBVLabel>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </>
                :
                <Grid container sx={{
                    height: '100vh'
                }}><LBVLoading/></Grid>
            }
        </>
    )

}

function DesktopView (
    props: {
        placeDetail: any,
        form: any,
        priceModel: any[],
        setForm: (data: any) => void,
        setPriceModel: (data: any) => void,
        setShowImageModel: (e: any) => void,
        setShowRoomDetail: (e: any) => void
    }
) {
    const { placeDetail, form, setForm, priceModel, setPriceModel, setShowImageModel, setShowRoomDetail } = props
    const [ showShare, setShowShare ] = useState(false)
    const [ selectedRoom, setSelectedRoom ] = useState("")
    const [ showDetail, setShowDetail ] = useState(false)

    const [ dateInfo, setDateInfo ] = useState(null)
    const [ guestInfo, setGuestInfo ] = useState({
        adult: 1,
        kids: 0
    })
    const [ roomInfo, setRoomInfo ] = useState(1)

    useEffect(() => {
        if (placeDetail) { setSelectedRoom(placeDetail.roomType[0]._id) }
    }, [placeDetail])

    useEffect(() => {
        console.log(dateInfo)
    }, [dateInfo])

    useEffect(() => {
        if ((placeDetail && placeDetail.rooms) && selectedRoom) {
            let rooms = null
            for (let i = 0; i < placeDetail.rooms.length; i ++) {
                let room =  placeDetail.rooms[i]
                if (selectedRoom == room._id) {
                    rooms = room;
                    break;
                }
            }
            console.log('here rooms info', rooms)
            let priceList = rooms.priceList ? [
                {
                    date: null,
                    day: null,
                    price: rooms.price
                },
                ...rooms.priceList,
            ] : null
            setPriceModel(priceList)
        }
        setForm( (prev : any) => ({
            ...prev
        }))
    }, [selectedRoom])

    return (
        <>
            {
                placeDetail ?
                <Grid container xs={12}>
                    {/* Title */}
                    <Grid container>
                        <Grid item xs={10}>
                            <LBVTitleLabel style={{
                                fontSize: 24,
                                fontWeight: 500
                            }}>{placeDetail.name}</LBVTitleLabel>
                        </Grid>
                        <Grid item xs={2} spacing={1} alignContent={"center"} textAlign={"right"}>
                            <div style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                setShowShare(true)
                            }}>
                                <FaArrowUpFromBracket/>
                                <LBVLabel style={{
                                    marginLeft: 5
                                }}>Share</LBVLabel>
                            </div>
                            {
                                showShare &&
                                <Grid p={2} sx={{
                                    position: 'absolute',
                                    marginTop: -2
                                }}>
                                    <div style={{
                                        position: 'fixed',
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0
                                    }} onClick={() => {
                                        setShowShare((prev) => false)
                                    }}/>
                                    <ShareLinkComponent shareUrl={`https://staging-lbv.wuebuild.com/${placeDetail.type}/${placeDetail.key}`}/>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {/* Images */}
                    <Grid container mt={2}>
                        <Grid xs={6} p={0.3}>
                            <img src={placeDetail.propertyImage[0]} height={406} width={'100%'} style={{
                                borderTopLeftRadius: 16,
                                borderBottomLeftRadius: 16
                            }}/>
                        </Grid>
                        <Grid container xs={6}>
                            {
                                placeDetail.propertyImage.map( (value: any, index: number) => {
                                    if (index > 0 && index < 5) {
                                        return (
                                            <Grid item xs={6} p={0.3}>
                                                <img src={value} width={'100%'} height={200} style={index == 2 ? {
                                                    borderTopRightRadius: 16,
                                                } : index == 4 ? {
                                                    borderBottomRightRadius: 16
                                                } : {}}/>
                                                {index == 4 &&
                                                    <Grid textAlign={"right"} pr={2}>
                                                        <LBVLabel style={{
                                                            cursor: 'pointer'
                                                        }}><span onClick={() => { setShowImageModel(true) } }>View All Photos</span></LBVLabel>
                                                    </Grid>
                                                }
                                            </Grid>
                                        )
                                    }
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid container mt={2} xs={12}>
                        <LBVLabel>
                            <div dangerouslySetInnerHTML={{__html: placeDetail.description}}/>
                        </LBVLabel>
                    </Grid>
                    <Grid container mt={2} xs={12} lg={3}>
                        <Grid item xs={12}>
                            <LBVTitleLabel>
                                Select Check-in Date
                            </LBVTitleLabel>
                        </Grid>
                        <Grid item container xs={12}>
                            <LBVDateInput isOpenDetail={showDetail} setDateInfo={(e:any) => {
                                setDateInfo(e)
                            }} />
                        </Grid>
                    </Grid>
                    <Grid container mt={2} xs={12} lg={6}>
                        <Grid item xs={12}>
                            <LBVTitleLabel>
                                Guest Information
                            </LBVTitleLabel>
                        </Grid>
                        <Grid item container p={1} xs sx={{
                                background: '#f8fafc',
                                borderRadius: 2,
                                borderTopRightRadius: 1,
                                borderBottomRightRadius: 1
                            }}>
                            {/* <Grid item mt={0.5} mr={1}>
                                <PersonIcon sx={{
                                    color: '#957c64'
                                }}/>
                            </Grid> */}
                            <Grid item container xs>
                                <LBVNumberInput
                                    value={guestInfo.adult}
                                    maxNumber={null}
                                    onChange={(e:any) => { 
                                        setGuestInfo(prev => ({
                                            ...prev,
                                            adult:  e
                                        }))
                                    }}
                                    isFlex={true}
                                />
                            </Grid>
                            <Grid item container xs={4} ml={1} mt={1}>
                                <LBVLabel style={{
                                    color: '#a5a6a8'
                                }}>Adult</LBVLabel>
                            </Grid>
                        </Grid>
                        <Grid item p={1} container xs sx={{
                                background: '#f8fafc',
                                borderRadius: 2,
                                borderTopRightRadius: 1,
                                borderBottomRightRadius: 1
                            }}>
                                <Grid item container xs>
                                    {/* <LBVInput value={guestInfo.kids} onChange={(e) => {
                                        setGuestInfo(prev => ({
                                            ...prev,
                                            kids:  e.currentTarget.value.replace(/\D/g, "")
                                        }))
                                    }}/> */}
                                    <LBVNumberInput
                                        value={guestInfo.kids}
                                        maxNumber={null}
                                        allowZero={true}
                                        onChange={(e:any) => { 
                                            setGuestInfo(prev => ({
                                                ...prev,
                                                kids:  e
                                            }))
                                        }}
                                        isFlex={true}
                                    />
                                </Grid>
                                <Grid item container xs={4} ml={1} mt={1}>
                                    <LBVLabel style={{
                                        color: '#a5a6a8'
                                    }}>Children</LBVLabel>
                                </Grid>
                        </Grid>
                    </Grid>
                    <Grid container mt={2} xs={12} lg={3}>
                        <Grid item xs={12}>
                            <LBVTitleLabel>
                                Rooms
                            </LBVTitleLabel>
                        </Grid>
                        <Grid item container p={1} sx={{
                                background: '#f8fafc',
                                borderRadius: 2,
                                borderTopRightRadius: 1,
                                borderBottomRightRadius: 1
                            }}>
                                <LBVNumberInput
                                    value={roomInfo}
                                    maxNumber={null}
                                    onChange={(e:any) => { setRoomInfo(e) }}
                                    isFlex={true}
                                />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <RoomTable data={placeDetail} 
                            formInfo={{
                                dateInfo,
                                ...guestInfo,
                                rooms: roomInfo
                            }}
                            setShowRoomDetail={(e: any) => { setShowRoomDetail({
                                open: true,
                                data: e
                            }) }}
                            // dateInfo={dateInfo} guestInfo={guestInfo} roomInfo={roomInfo} 
                            showCalendar={() => { setShowDetail(true) }}/>
                    </Grid>
                    {
                        placeDetail.houseRules &&
                        <Grid container mt={2}>
                            <LBVTitleLabel>
                                Things to know
                            </LBVTitleLabel>
                            <Grid container>
                                <LBVLabel>
                                    <div dangerouslySetInnerHTML={{__html: placeDetail.houseRules}}/>
                                </LBVLabel>
                            </Grid>
                        </Grid>
                    }
                    <Grid container mt={2}>
                        <CommentComponent propertyId={placeDetail._id} />
                    </Grid>
                    {
                        placeDetail.mapInfo &&
                        <Grid container xs={12} mt={2}>
                            <LBVTitleLabel style={{fontSize: '24px !important'}}>
                                Location
                            </LBVTitleLabel>
                            <Grid container>
                                <iframe src={placeDetail.mapInfo} 
                                    width="600" height="450" 
                                    style={{ border: 0 }}
                                    allowFullScreen={false}
                                    loading="lazy" 
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                :
                <Grid container sx={{
                    height: '100vh'
                }}><LBVLoading/></Grid>
            }
        </>
    )
}

export default Place