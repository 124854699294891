import React, { useState } from "react";
import {
    Grid,
    InputAdornment,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {
    defaultCountries,
    FlagImage,
    parseCountry,
    usePhoneInput
} from "react-international-phone";
import { LBVLabel } from "../label/LBVLabel";
import "react-international-phone/style.css";
import { LBVInput } from "./LBVInput";

export default function MuiPhone ({
    value,
    onChange,
    enterAction,
    ...restProps
}) {

    const [ queryCountry, setQueryCountry ] = useState("")
    const [ openSelect, setOpenSelect ] = useState(false)
    
    const {
        phone,
        forceDialCode,
        handlePhoneValueChange,
        inputRef,
        country,
        setCountry
    } = usePhoneInput({
        forceDialCode: true,
        defaultCountry: "id",
        value,
        countries: defaultCountries,
        onChange: (data) => {
            onChange(data.phone);
        }
    });

    return (
        <TextField
            fullWidth
            variant="outlined"
            label=""
            color="primary"
            placeholder="Phone number"
            value={value}
            onChange={handlePhoneValueChange}
            type="tel"
            inputRef={inputRef}
            onKeyDown={(e) => {
                if(e.key == 'Enter') {
                    if (enterAction) {
                        enterAction()
                    }
                }
            }}
            sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#344767"
                  },
                  "&.Mui-disabled input": {
                    backgroundColor: "#f5f5f5"
                  }
                },
                input: {
                    border: "none",
                    margin: 0,
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: "#f8fafc",
                    fontSize: '16px'
                },
                "& fieldset": { border: 'none' },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ marginRight: "2px", marginLeft: "-8px" }}
                    >
                        <Select
                            // disabled={true} // default Indo
                            onAnimationEnd={() => inputRef.current.focus()}
                            MenuProps={{
                                style: {
                                    height: "300px",
                                    width: "360px",
                                    top: "10px",
                                    left: "-24px"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                autoFocus: false
                            }}
                            sx={{
                                width: "max-content",
                                // Remove default outline (display only on focus)
                                fieldset: {
                                    display: "none"
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                    fieldset: {
                                        display: "block"
                                    }
                                },
                                // Update default spacing
                                ".MuiSelect-select": {
                                    padding: "8px",
                                    paddingRight: "24px !important"
                                },
                                svg: {
                                    right: 0
                                }
                            }}
                            value={country}
                            onChange={(e) => { setCountry(e.target.value) }}
                            renderValue={(value) => (
                                <FlagImage itemID={value} iso2={value.iso2} style={{ display: "flex" }} />
                            )}
                        >
                            <ListSubheader sx={{ minWidth: 200}}>
                                <Grid pt={1}>
                                    <LBVInput autoFocus={true} placeHolder="Search Country" value={queryCountry} 
                                    onKeyDown={(e) => {
                                        e.stopPropagation()
                                    }}
                                    onChange={(e) => {
                                        console.log(e, queryCountry)
                                        setQueryCountry(e.currentTarget.value)
                                    }}/>
                                </Grid>
                            </ListSubheader>
                            {
                                defaultCountries.filter(function(name) { return name[0].toLowerCase().match(queryCountry) }).map((c) => {
                                    const country = parseCountry(c);
                                    return (
                                    <MenuItem key={country.iso2} value={country.iso2} onKeyDown={(e) => e.stopPropagation()}>
                                        <FlagImage
                                            itemID={value}
                                            iso2={country.iso2}
                                            style={{ marginRight: "8px" }}
                                        />
                                        <LBVLabel marginRight="8px">{country.name}</LBVLabel>
                                        <LBVLabel color="gray">+{country.dialCode}</LBVLabel>
                                    </MenuItem>
                                )}
                            )}
                        </Select>
                    </InputAdornment>
                )
            }}
            {...restProps}
        />
    );
}