import { client, errorValidation } from "./service";

export const getComments = async function ({userId, propertyId}) {
    try {
        let response = await client.get(`/comments/${propertyId}?userId=${userId || ''}`);
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data, error: null };
    } catch (e) {
        return { data: null, ...errorValidation(e) }
    }
}

export const submitComment = async function ({
    email,
    comment,
    rating,
    propertyId,
}) {
  try {
    let response = await client.post(`/comments/create`, {
        email: email || '',
        comment: comment || '',
        rating: rating || '',
        propertyId: propertyId || '',
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data, error: null };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    console.log('dataResponse', e.response)
    console.log('dataResponse', e.response.data.data)
    return { data: e.response.data.data || null, ...errorValidation(e) }
  }
}

export const updateComment = async function ({
    _id,
    email,
    comment,
    rating,
    propertyId,
}) {
    try {
        let response = await client.post(`/comments/update`, {
            _id: _id || '',
            email: email || '',
            comment: comment || '',
            rating: rating || '',
            propertyId: propertyId || '',
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data, error: null };
      } catch (e) {
        // return { error: e.response.data.errorCode }
        return { data: null, ...errorValidation(e) }
      }
}