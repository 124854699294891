import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LBVLabel, LBVTitleLabel } from "../../label/LBVLabel";
import { LBVInput, LBVSelect } from "../../input/LBVInput";
import LBVNumberInput from "../../input/LBVCounterNumber";
import { childrenAge, isPhone } from "../../../helper/constant";

function Guest (
    props: {
        onSave: (e: any) => void,
        onChangeGuest: (e: any) => void
    }
) {

    const { onSave, onChangeGuest } = props

    const [ guest, setGuests ] = useState({
        adult: 1,
        children: 0,
        childrenAge: 1,
        rooms: 1
    })

    const onChange = (e: any) => {
        setGuests( prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        onChangeGuest(guest)
    }, [guest])

    return (
        <Grid container>
            <LBVTitleLabel>{"How Many Guest ?"}</LBVTitleLabel>
            <Grid item container xs={12} alignItems={"center"}>
                <Grid item xs={6}>
                    <LBVLabel style={{fontSize: 13}}>Adult</LBVLabel>
                </Grid>
                <Grid item xs={6}>
                    <LBVNumberInput
                        maxNumber={null}
                        onChange={(e: any) => { onChange({ adult: e }) }}
                        isFlex={true}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} mt={1} alignItems={"center"}>
                <Grid item xs={6}>
                    <LBVLabel style={{fontSize: 13}}>Children</LBVLabel>
                </Grid>
                <Grid item xs={6}>
                    <LBVNumberInput
                        value={guest.children}
                        maxNumber={null}
                        allowZero={true}
                        onChange={(e: any) => { onChange({ children: e }) }}
                        isFlex={true}
                    />
                </Grid>
                {
                    guest.children > 0 &&
                    <Grid container mt={1} alignItems={"center"}>
                        <Grid item xs={6}>
                            <LBVLabel style={{fontSize: 13}}>Children Age</LBVLabel>
                        </Grid>
                        <Grid item xs={6}>
                            {/* <LBVNumberInput
                                value={guest.childrenAge}
                                maxNumber={10}
                                onChange={(e: any) => { onChange({ childrenAge: e }) }}
                                isFlex={true}
                            /> */}
                            <LBVSelect
                                value={guest.childrenAge}
                                onChange={(e) => { onChange({ childrenAge: e }) }}
                                inDialog={true}
                                placeHolder="Max 10 y.o"
                                options={childrenAge}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item container xs={12} mt={1} alignItems={"center"}>
                <Grid item xs={6}>
                    <LBVLabel style={{fontSize: 13}}>Rooms</LBVLabel>
                </Grid>
                <Grid item xs={6}>
                    <LBVNumberInput
                        value={guest.rooms}
                        maxNumber={null}
                        allowZero={true}
                        onChange={(e: any) => { onChange({ rooms: e }) }}
                        isFlex={true}
                    />
                </Grid>
            </Grid>
            {!isPhone() && 
                <Grid mt={1} xs={12} textAlign={"right"}>
                    <Button variant="contained" onClick={() => {
                        onSave(guest)
                    }}>Save</Button>
                </Grid>
            }
        </Grid>
    )
}

export default Guest