import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { Grid } from "@mui/material"
import { color } from "../../helper/constant"

function LegalInformation ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Legal Information</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    Local Bali Travel Agent is a licensed and registered travel agency based in Bali, Indonesia. Our primary goal is to provide exceptional travel services to our clients, ensuring their stay in Bali is both enjoyable and memorable. We pride ourselves on our local knowledge, personalized service, and commitment to customer satisfaction.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{ fontWeight: 'bold', color: color.primary}}>Our Legal Status</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    <span style={{ fontWeight: 'bold', color: color.primary}}>Business Registration:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Our travel agency is officially registered with the Indonesian Ministry of Tourism and Creative Economy. This registration ensures that we comply with all the regulations and standards set forth by the government (NIB: 2408230129019/ PT LOCAL BALI INDONESIA).
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    <span style={{ fontWeight: 'bold', color: color.primary}}>Operating License:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We hold a valid operating license that permits us to offer travel-related services, including accommodation bookings, tour packages, and transportation arrangements. Our license number is available upon request for verification purposes.
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    <span style={{ fontWeight: 'bold', color: color.primary}}>Insurance Coverage:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                To protect our clients and ensure their peace of mind, we have comprehensive insurance coverage that includes liability insurance for all our services and activities.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default LegalInformation