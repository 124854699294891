import React, { useState, useEffect } from "react"
import LBVNav from "../component/Navbar"
import BookingMain from "../component/booking/Main"
import { Grid, ImageList, ImageListItem } from "@mui/material"
import { Container } from "shards-react"
import { LBVLabel, LBVTitleLabel } from "../component/label/LBVLabel"
import PropertyCard from "../component/card/PropertyCard"
import { isPhone } from "../helper/constant"
import { useWindowSize } from "../helper/windowResize"
import Footer from "../component/Footer"
import { useNavigate } from "react-router-dom"
import { getProperties } from "../services/propertiesService"
import LBVLoading from "../component/loading/LBVLoading"
import Slider from "react-slick";
import { CustomSlickNextArrow, CustomSlickPrevArrow } from "../component/slider/SlideButton"

function Main () {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isPhone(1000) ? isPhone(770) ? 2 : 3 : 4,
        slidesToScroll: isPhone(1000) ? isPhone(770) ? 2 : 3 : 4,
        nextArrow: <CustomSlickNextArrow />,
        prevArrow: <CustomSlickPrevArrow />
    };

    const [ hotelList, setHotelList ] = useState([])
    const [ windowSize, setWindowSize ] = useState(useWindowSize())
    const navigate = useNavigate()

    useEffect(() => {
        loadProperties()
    }, [])


    const loadProperties = async () => {
        let { data, error } = await getProperties({
            location: '',
            limit: 10
        })
        if (error) { console.log(error) }
        if (data) { 
            data = data.concat({
                type: "view_all"
            })
            setHotelList(data) 
        }
    }

    return (
        <>
            <Grid container xs={12} md={12} justifyContent={"left"} alignItems={'top'}>
                <LBVNav />
            </Grid>
            <Grid xs={11} md={11} lg={11}>
                <BookingMain />
            </Grid>
            <Grid mt={4} xs={11} md={11} lg={11}>
                <LBVTitleLabel>Popular Destination</LBVTitleLabel>
                {/* <div style={{
                    display: 'flex',
                    overflowX: 'auto',
                    paddingBottom: 20,
                    paddingLeft: 10,
                    marginLeft: isPhone() ? -18 : 0
                }}>
                {
                    hotelList.map(value => {
                        return(
                            <div style={{
                                padding: 8,
                                flexShrink: 0,
                                flexGrow: 0,
                                flexBasis: isPhone(1000) ? isPhone(770) ? '50%' : '30%' : '25%'
                            }}>
                                <PropertyCard value={value} onSelect={() => {
                                    navigate(`/${value["type"]}/${value["key"]}`)
                                }} selected={false}/>
                            </div>
                        )
                    })
                }
                </div> */}
                {
                    (hotelList && hotelList.length > 0) &&
                    <Slider {...settings}>
                    {
                        hotelList.map(value => {
                            if (value.type && value.type == 'view_all') {
                                return (
                                    <Grid container xs={12} sx={{
                                        minHeight: 80,
                                        cursor: 'pointer'
                                    }} textAlign={"center"}
                                        margin={'auto'}
                                        mt={'45%'}
                                        onClick={() => {
                                            navigate('/properties')
                                        }}
                                    >
                                        View Other Property
                                    </Grid>
                                )
                            } else {
                                return(
                                    <Grid p={1} pb={5}>
                                        <PropertyCard value={value} onSelect={() => {
                                            navigate(`/${value["type"]}/${value["key"]}`)
                                        }} selected={false}/>
                                    </Grid>
                                )
                            }
                        })
                    }
                    </Slider>
                }
            </Grid>
            <Grid mt={2}>
                <Grid>
                    <LBVTitleLabel>About Us</LBVTitleLabel>
                </Grid>
                <Grid>    
                    <LBVLabel>
                        Founded in 2020 in Bali, Currently, Local Bali Villas is a continuously growing new start-up company. Local Bali Villas’ mission is to make people around the world easier to find likewise their second home with the best service and become the unforgettable moments during their stay-cation in Bali.
                        Local Bali Villas has been working together with more than thousands of the best villas in Bali for more than 10 years of experience in tourism. As foreseen, the booking service dilemma is commonly caused by a lack of miscommunication between both guests and the villas’ owner therefore we commit to minimize it to meet the guests’ expectations.
                    </LBVLabel>
                </Grid>
            </Grid>
        </>
    )
}

export default Main