import React, { useState } from "react";
import { LBVLabel } from "../label/LBVLabel";
import { Grid, TextField } from "@mui/material";
import Select from 'react-select';
import { color } from "../../helper/constant";

function LBVInput ({
    styles = {},
    label = "",
    disabled = false,
    value,
    defaultValue = null,
    type = "text",
    rows = 0,
    maxRows = 0,
    fullWidth = true,
    placeHolder = "",
    onChange = (e) => {},
    showAlert = null,
    showInfo = null,
    showCharacter = null,
    autoFocus = false,
    inputProps = {},
    enterAction = () => {},
    onKeyDown = () => {},
    isError = false
}) {

  const InputProps = {
    ...inputProps
    // inputProps: { min: "2020-05-01", max: "2020-05-04"} 
  }

  return (
      <>
        {/* <TCLabel style={{fontSize: 13}}>{label}</TCLabel> */}
        <Grid container alignItems={'flex-end'} spacing={1}>
          <Grid item>
            <LBVLabel style={{fontSize: 13}}>{label} {isError ? <span style={{color: 'red'}}>*</span> : ''}</LBVLabel>
          </Grid>
          <Grid item sx={{pt:'0px !important', pb: 0.1}}>
            {/* {showInfo &&  <TCLabel style={{fontSize: 11, color: color.blueIndoor_2}}>{showInfo}</TCLabel>} */}
            {/* {showAlert && <LBVLabel style={{fontSize: 11, color: color.pink}}>{showAlert}</LBVLabel>} */}
          </Grid>
        </Grid>
        <TextField autoFocus={autoFocus} 
          autoComplete="off"
          fullWidth={fullWidth} 
          placeholder={placeHolder} 
          multiline={type == "textarea"} 
          type={type} rows={rows} maxRows={maxRows} 
          onKeyDown={(e) => {
            if (onKeyDown) { onKeyDown(e) }
            if (type === 'number' && e.code === 'Minus') {
                e.preventDefault();
            }
            if(e.key == 'Enter') {
              if (enterAction) {
                  enterAction()
              }
          }
          }}
          disabled={disabled} value={value} defaultValue={defaultValue} 
          onChange={onChange}
          sx={{...LBVInputStyle, ...styles}} 
          { ...InputProps }
        />
        {showAlert && <LBVLabel style={{fontSize: 11, color: color.pink}}>{showAlert}</LBVLabel>}
        {showCharacter && 
          <Grid xs={12} textAlign={'right'}>
            <LBVLabel style={{fontSize: 10, color: color.grey}}>{showCharacter}</LBVLabel>
          </Grid>
        }
      </>
  )
}

function LBVSelect ({
  styles = {},
  label = "",
  disabled = false,
  value = {},
  defaultValue = "",
  placeHolder = "",
  onChange = (e) => {},
  options = [],
  showAlert = null,
  showInfo = null,
  showCharacter = null,
  inDialog= false,
}) {
  return (
    <>
      {/* <TCLabel style={{fontSize: 13}}>{label}</TCLabel> */}
      <Grid container alignItems={'flex-end'} spacing={1}>
        <Grid item>
          <LBVLabel style={{fontSize: 13}}>{label}</LBVLabel>
        </Grid>
        <Grid item sx={{pt:'0px !important'}}>
          {/* {showInfo &&  <TCLabel style={{fontSize: 11, color: color.blueIndoor_2}}>{showInfo}</TCLabel>} */}
          {showAlert && <LBVLabel style={{fontSize: 11, color: color.pink}}>{showAlert}</LBVLabel>}
        </Grid>
      </Grid>
      <Select
        menuPosition="fixed"
        menuPortalTarget={inDialog ? document.querySelector('.MuiDialog-root') : document.body} 
        placeholder={placeHolder}
        options={options}
        value={value}
        defaultValue={defaultValue}
        isDisabled={disabled}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          ...LBVSelectStyle,
          ...styles,
          
        }}
        onChange={onChange}
      />
      {/* {showAlert && <TCLabel style={{fontSize: 11, color: color.pink}}>{showAlert}</TCLabel>} */}
      {showCharacter && <LBVLabel style={{fontSize: 11, color: color.gray}}>{showCharacter}</LBVLabel>}
      {/* <TextField fullWidth={fullWidth} placeHolder={placeHolder} multiline={type == "textarea"} 
        type={type} rows={rows} maxRows={maxRows}
        disabled={disabled} value={value} defaultValue={defaultValue} 
        onChange={onChange}
        sx={{...TCInputStyle, ...styles}} /> */}
    </>
  )
}

export const LBVSelectStyle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    // borderColor: "#344767",
    fontSize: '13px',
    border: '0.5px',
    backgroundColor: '#f8fafc',
    borderRadius: 2
  }),
  option: (base) => ({
    ...base,
    fontSize: '13px',
    height: '100%',
  }),
  menuPortal: base => ({
    ...base,
    fontSize: '13px'
  }),
  menuList: (base) => ({
    ...base,
    fontSize: '13px',
  }),
  singleValue:(base) => ({
    ...base,
    fontSize: '13px',
    color: color.primary,
  }),
  // "& .MuiOutlinedInput-root": {
  //   "&.Mui-focused fieldset": {
  //     borderColor: "#344767"
  //   },
  //   "&.Mui-disabled input": {
  //     backgroundColor: "#f5f5f5"
  //   }
  // },
  // input: {
  //     border: "none",
  //     margin: 0,
  //     padding: 1,
  //     borderRadius: 2,
  //     fontSize: '14px',
  //     backgroundColor: "#f8fafc"
  // },
  // "& fieldset": { border: 'none' },
}

export const LBVInputStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#344767"
      },
      "&.Mui-disabled input": {
        backgroundColor: "#f5f5f5"
      }
    },
    input: {
        border: "none",
        margin: 0,
        padding: 1,
        borderRadius: 2,
        fontSize: '14px',
        backgroundColor: "#f8fafc"
    },
    textarea: {
      backgroundColor: "#f8fafc",
      borderRadius: 2,
      margin: 0,
      padding: 1,  
    },
    "& fieldset": { border: 'none' },
    // "& fieldset": { border: '0.5px solid #2196f366', borderRadius: 2, },
}

export { LBVInput, LBVSelect }