import React, { useEffect, useState } from "react"
import { Avatar, Grid, Button } from "@mui/material"
import { Card } from "shards-react"
import { Rating } from 'react-simple-star-rating'
import { LBVInput } from "../input/LBVInput"
import { LBVLabel } from "../label/LBVLabel"
import { toast } from "react-toastify"
import { getComments, submitComment, updateComment } from "../../services/commentService"

function CommentComponent ({
    propertyId
}) {

    const [ reviews, setReviews ] = useState([
        // {
        //     comment: "The best place to stay",
        //     user: {
        //         avatar: "",
        //         name: "Jono"
        //     },
        //     rating: 5
        // }
    ])

    const [ commentForm, setCommentForm ] = useState({
        rating: 0,
        email: "",
        comment: ""
    })
    const [ joi, setJoi ] = useState({
        email: null
    })
    const [ rating, setRating ] = useState(0)

    useEffect(() => {
        loadComment()
    }, [])

    useEffect(() => {
        let ratingCount = 0
        for (let i = 0; i < reviews.length; i ++) {
            ratingCount += reviews[i].rating
        }
        setRating(Math.ceil(ratingCount / reviews.length))
    }, [reviews])

    useEffect(() => {
        setJoi({
            email: commentForm.email ? validateEmail(commentForm.email) ? false : true : true,
        })
    }, [commentForm])

    const onChange = (e) => {
        setCommentForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const loadComment = async () => {
        try {
            let { data, error } = await getComments({
                propertyId
            })
            if (data) { setReviews(data) }
            console.log(error)
        } catch (error) {
            console.log(error)
        }
    }

    const sendComment = async () => {
        try {

            if (commentForm._id) {
                let { data, error } = await updateComment({
                    _id: commentForm._id,
                    email: commentForm.email,
                    comment: commentForm.comment,
                    rating: commentForm.rating,
                    propertyId,
                })
                if (error) { 
                    if (data) { throw { error, data} }
                    throw error 
                }
            } else {
                let { data, error } = await submitComment({
                    email: commentForm.email,
                    comment: commentForm.comment,
                    rating: commentForm.rating,
                    propertyId,
                })
                if (error) { 
                    if (data) { throw { error, data} }
                    throw error 
                }
            }
            setCommentForm({
                rating: 0,
                email: "",
                comment: ""
            })
            loadComment()
            toast( 'Thank you for your review', {
                toastId: "comment-error",
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        } catch (error) {
            console.log('dataResponse', error)
            if (error.data) { 
                setCommentForm(prev => ({
                    ...prev,
                    _id: error.data._id
                }))
            }
            toast( error.error ? error.error : error, {
                toastId: "comment-error",
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
           return (true)
        }
        return (false)
    }

    return (
        <Grid xs={12}>
            <hr />
            <Grid container xs={12} pb={2}>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={12} mt={1} pl={2} pr={2}>
                        <Rating initialValue={commentForm.rating} onClick={(e) => {
                            onChange({rating: e})
                        }} />
                    </Grid>
                    <Grid item xs={12} lg={8} mt={1} pl={2} pr={2}>
                        <LBVInput placeHolder="Email" value={commentForm.email} onChange={(e) => {
                            onChange({email: e.currentTarget.value})
                        }}/>
                    </Grid>
                    <Grid item xs={12} lg={12} >
                        <LBVInput type={"textarea"} value={commentForm.comment} rows={4} 
                            onChange={(e) => {
                                onChange({comment: e.currentTarget.value})
                            }}
                        placeHolder="Write down your review ..."/>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12}>
                    <Grid item xs={12} lg={6} textAlign={"right"}>
                        <Button variant="contained" disabled={joi.email} onClick={() => {
                            console.log(commentForm)
                            if (commentForm.email && commentForm.rating) {
                                sendComment()
                                return;
                            }
                            toast( !commentForm.email ? "Email can't be empty" : 'Coming Soon', {
                                toastId: "comment-error",
                                position: "bottom-center",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            })
                        }}>{commentForm._id ? 'Update' : 'Submit'} Review</Button>
                    </Grid>
                </Grid>
            </Grid>
            {/* <hr/> */}
            <Grid container mt={2} spacing={0.5} textAlign={"center"} alignContent={"center"}>
                <Grid item container>
                    <Grid item mt={.5}>
                        {rating || 0}
                    </Grid>
                    <Grid item ml={.5}>
                        <Rating readonly={true} initialValue={rating} size={20}/>
                    </Grid>
                </Grid>
                {/* <div style={{ width: 1, height: '100%', backgroundColor: 'rgba(0,0,0,.1)'}}/> */}
                <Grid item>
                    {reviews.length} Reviews
                </Grid>
            </Grid>
            <Grid container mt={1} xs={12}>
                {
                    reviews.map( value => {
                        return (
                            <Grid item xs={12} lg={4}>
                                <Card>
                                    <Grid container>
                                        <Grid item xs={12} p={1} sx={{
                                            minHeight: 150
                                        }} textAlign={"center"} alignContent={"center"}>
                                            <div>"{value.comment}"</div>
                                        </Grid>
                                        <Grid item container p={1} pb={2} mr={2} xs={12} justifyContent={"right"}
                                            alignItems={"center"}
                                        >
                                            <Avatar sx={{mr: 0.5, ml: 0.5, width: 25, height: 25}}/>{value.user.name}
                                            <Grid p={0} m={0} xs={12} textAlign={"right"}><Rating readonly={true} initialValue={value.rating} size={15}/></Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}

export default CommentComponent