import React, { useEffect, useState } from "react"
import { Button, Checkbox, Grid } from "@mui/material"
import { Card } from "shards-react"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { LBVInput, LBVSelect } from "../../component/input/LBVInput"
import moment from "moment";
import { submitOrder } from "../../services/bookingService"
import { childrenAge, hoursOptions, titleOptions } from "../../helper/constant"
import { currencyFormat } from "../../helper/numberFormat"
import LBVLoading from "../../component/loading/LBVLoading"
import MuiPhone from "../../component/input/LBVCountryPicker"
import { toast } from "react-toastify"


function OrderSummary ({
    bookingData,
    setShowBookingSummary
}) {

    const [ joiForm, setJoiForm ] = useState({
        email: false,
        title: false,
        firstName: false,
        lastName: false,
        phoneNumber: false,
        arrivalTime: false
    })
    const [ form, setForm ] = useState({
        email: "",
        title: {label: "Mr/Mrs", value: "male"},
        firstName: "",
        lastName: "",
        phoneNumber: "+62",
        arrivalTime: {label: "14:00", value: "14:00"},
        specialRequest: ""
    })
    const [ coupon, setCoupon ] = useState("")
    const [ usedCoupon, setUsedCoupon ] = useState(null)
    const [ couponAlert, setCouponAlert ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ agreeCondition, setAgreeCondition ] = useState(true)

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            ...bookingData
        }))
    }, [bookingData])

    useEffect(() => {
        setJoiForm({
            email: form.email ? validateEmail(form.email) ? false : true : true,
            title: form.title ? false : true,
            firstName: form.firstName ? false : true,
            lastName: form.lastName ? false : true,
            phoneNumber: form.phoneNumber.length > 10 ? false : true,
            arrivalTime: form.arrivalTime ? false : true
        })
    }, [form])

    const onChange = (e) => {
        setForm( (prev) => ({
            ...prev,
            ...e
        }))
    }

    const checkCoupon = async () => {
        try {
            if (!coupon) { setCouponAlert("Coupon code can't be empty") }
            setCouponAlert("")
            toast('Coupon not found', {
                toastId: "coupon-error",
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
            setUsedCoupon()
        } catch (error) {
            console.log(error)
        }
    }

    const functionSubmitOrder = async () => {
        try {
            setLoading(true)
            let { data, error } = await submitOrder({
                ...form,
                childrenAge: form.childrenAge ? form.childrenAge.value : null,
                arrivalTime: form.arrivalTime ? form.arrivalTime.value : null,
                title: form.title ? form.title.value : null
            })
            setLoading(false)
            if (data) { 
                setShowBookingSummary(false, data.paymentLink)
                // if (data.paymentLink) {
                //     // window.open(data.paymentLink, '_blank')
                //     // var win = window.open(data.paymentLink, '_blank');
                //     // win.focus();
                //     // window.location.replace(data.paymentLink)
                //     // var windowReference = window.open();
                //     // myService.getUrl().then(function(url) {
                //     //     windowReference.location = url;
                //     // });
                //     // setTimeout(() => {
                //     //     window.open(data.paymentLink, '_top');
                //     // }, 100)
                // }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
           return (true)
        }
        return (false)
    }
    
    return (
        <Grid container>
            <Grid item container xs={12} lg={12} spacing={3}>
                <Grid item mt={1} xs={12} lg={6}>
                    <Card>
                        <Grid container p={2}>
                            <Grid item xs={12}>
                                {bookingData ? bookingData.placeName : ''}
                            </Grid>
                            <Grid item xs={12}>
                                <LBVLabel>{bookingData ? bookingData.roomName : ''}</LBVLabel>
                            </Grid>
                        </Grid>
                    </Card>
                    <Card style={{marginTop: 10}}>
                        <Grid item p={2} pb={0}>
                            Summary
                            <Grid item xs={12} mt={1}>
                                <LBVLabel>Promo Code</LBVLabel>
                                <Grid container item xs={12}>
                                    <Grid item xs={9}>
                                        <LBVInput value={coupon} showAlert={couponAlert} onChange={(e) => {
                                            setCoupon(e.currentTarget.value.toUpperCase())
                                        }}/>
                                    </Grid>
                                    <Grid item xs textAlign={"right"}>
                                        <Button variant="contained" onClick={() => {
                                            checkCoupon()
                                        }}>Check</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container p={2} pt={0}>
                            <Grid item xs={12} mt={1}>
                                <LBVLabel>Stay Period</LBVLabel>
                            </Grid>
                            {
                                bookingData && bookingData.days.length > 0 &&
                                <Grid item container xs={12}>
                                    {/* {
                                        bookingData.priceList.map(value => {
                                            return (
                                                <Grid item container xs={12} lg={6}>
                                                    <Grid item xs={12}><LBVLabel subtitle>{moment(value.date).format('DD/MM/YYYY')}</LBVLabel></Grid>
                                                    <Grid item xs><LBVLabel bold>{currencyFormat(value.price)}</LBVLabel></Grid>
                                                </Grid>
                                            )
                                        })
                                    } */}
                                    <LBVLabel style={{marginRight: 4}}>{moment(bookingData.days[0]).format('DD/MM/YYYY')}</LBVLabel>
                                    {
                                        bookingData.days.length > 1 &&
                                        <LBVLabel>{`- ${moment(bookingData.days[bookingData.days.length - 1]).format('DD/MM/YYYY')}`}</LBVLabel>
                                    }
                                </Grid>
                            }
                            <Grid item xs={12} mt={1}>
                                <LBVLabel>Guests</LBVLabel>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs>
                                    <LBVLabel>{bookingData.adult} Adult</LBVLabel>
                                </Grid>
                                <Grid item xs>
                                    <LBVLabel>{bookingData.kids} Children</LBVLabel>
                                </Grid>
                                <Grid item xs>
                                    <LBVLabel>{bookingData.rooms || 1} Room</LBVLabel>
                                </Grid>
                            </Grid>
                            {
                                bookingData.kids > 0 &&
                                <Grid item xs={6} mt={1}>
                                    <LBVLabel style={{fontSize: 12}}>Children Age</LBVLabel>
                                    {/* <LBVNumberInput
                                        maxNumber={null}
                                        onChange={(e) => { onChange({ childrenAge: e }) }}
                                        isFlex={true}
                                    /> */}
                                    <LBVSelect
                                        value={form.childrenAge}
                                        onChange={(e) => { onChange({ childrenAge: e }) }}
                                        inDialog={true}
                                        placeHolder="Max 10 y.o"
                                        options={childrenAge}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} mt={1}>
                                <LBVLabel>Total Price</LBVLabel>
                            </Grid>
                            <Grid item xs={12} mt={1}>
                                <LBVTitleLabel>{currencyFormat(bookingData.totalPrice)} <span style={{fontSize: 13}}>x {bookingData.rooms || 1} Rooms</span></LBVTitleLabel>
                            </Grid>
                            <Grid item xs={12} mt={1}>
                                <LBVLabel>Price Details</LBVLabel>
                            </Grid>
                            {
                                bookingData && bookingData.days.length > 0 &&
                                <Grid item container xs={12}>
                                    {
                                        bookingData.priceList.map(value => {
                                            return (
                                                <Grid item container xs={12} lg={6}>
                                                    <Grid item xs={12}><LBVLabel subtitle>{moment(value.date).format('DD/MM/YYYY')}</LBVLabel></Grid>
                                                    <Grid item xs><LBVLabel bold>{currencyFormat(value.price)}</LBVLabel></Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                    {/* <LBVLabel>{moment(bookingData.days[0]).format('DD/MM/YYYY')}</LBVLabel> */}
                                </Grid>
                            }
                        </Grid>
                    </Card>
                </Grid>
                <Grid item mt={1} xs={12} lg={6}>
                    <Card>
                        <Grid container p={2}>
                            {/* <Grid item xs={12}>
                                Your Booking Summary
                            </Grid> */}
                            <Grid item xs={12}>
                                Guest Information
                                <Grid item mt={1}>
                                    <LBVInput label={"Email Address"} isError={joiForm.email} onChange={(e) => {
                                        onChange({email: e.currentTarget.value.trim()})
                                    }} value={form.email} />
                                </Grid>
                                <Grid item>
                                    <LBVSelect label="Title" value={form.title} options={titleOptions}
                                        inDialog={true}
                                        onChange={(e) => {
                                            onChange({ title: e })
                                        }}
                                     />
                                </Grid>
                                <Grid item>
                                    <LBVInput label="First Name" isError={joiForm.firstName} onChange={(e) => {
                                        onChange({firstName: e.currentTarget.value.trim()})
                                    }} value={form.firstName} />
                                </Grid>
                                <Grid item>
                                    <LBVInput label="Last Name" isError={joiForm.lastName} onChange={(e) => {
                                        onChange({lastName: e.currentTarget.value.trim()})
                                    }} value={form.lastName} />
                                </Grid>
                                <Grid item>
                                    <LBVLabel style={{fontSize: 13}}>Phone Number {joiForm.phoneNumber ? <span style={{color: 'red'}}>*</span> : ''}</LBVLabel>
                                    <MuiPhone 
                                        value={form.phoneNumber} onChange={(e) => {
                                            onChange({phoneNumber: e.trim()})
                                        }}
                                        enterAction={() => { }}
                                    />
                                </Grid>
                                <Grid item>
                                    <LBVSelect label="Arrival Time" value={form.arrivalTime} options={hoursOptions}
                                        inDialog={true}
                                        onChange={(e) => {
                                            onChange({ arrivalTime: e })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <hr/>
                                Special Request
                                <LBVInput type="textarea" rows={5} styles={{
                                    fontSize: 10
                                }} onChange={(e) => {
                                    onChange({specialRequest: e.currentTarget.value})
                                }} value={form.specialRequest}
                                    placeHolder="Please tell us if you have special request .."
                                />
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    fontStyle: 'italic'
                                }}>This reservation cannot be cancelled nor modified. Guests who do not arrive on the scheduled date will incur a 100% penalty of the reservation value.</div>
                            </Grid>
                            <Grid container xs={12} mt={1} textAlign={"left"}>
                                <Grid item xs={1.5}>
                                    <Checkbox checked={agreeCondition} onChange={() => {
                                        setAgreeCondition(!agreeCondition)
                                    }}/>
                                </Grid>
                                <Grid item xs mt={0.5}>
                                    <div style={{
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        fontStyle: 'italic'
                                    }}>By choosing to book, I acknowledge have read and agreed to the <a href="/about-us/terms-condition-order" target="_blank">terms and conditions</a></div>
                                </Grid>
                            </Grid>
                            <Grid p={2} xs={12} textAlign={"center"}>
                                <Button variant="contained" 
                                disabled={
                                    (joiForm.email || joiForm.firstName || joiForm.lastName ||
                                    joiForm.phoneNumber || joiForm.arrivalTime) || loading || !agreeCondition
                                }
                                sx={{
                                    minWidth: 200
                                }} onClick={() => {
                                    functionSubmitOrder()
                                }}>Book</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            {
                loading &&
                <LBVLoading />
            }
        </Grid>
    )
}

export default OrderSummary