// convert nominal to K, M

function convertPrice(num) {
    const SUFFIXES = ["", "k", "M", "G", "T", "P", "E"];
    // const SUFFIXES = ' KMBTqQsSOND'

    var tier = Math.log10(Math.abs(num)) / 3 | 0;
    if(tier == 0) return num;

    // var power = Math.floor(Math.log10(num));
    // var index = Math.floor(power / 3);
    // num = Math.round(num / Math.pow(10, (index * 3))); // first 3 digits of the number
    // return num.toFixed(1) + (SUFFIXES[index - 1] || ''); // default to no suffix if we get an out of bounds index

    var suffix = SUFFIXES[tier];
    var scale = Math.pow(10, tier * 3);
    var scaled = num / scale;
    return scaled.toFixed(1) + suffix;
}

export { convertPrice }