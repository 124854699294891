import React, { useEffect, ReactNode, useState } from "react";
import { Grid, Icon, Button, SwipeableDrawer, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LBVInput, LBVSelect } from "../input/LBVInput";
import { accomodationOptions, color } from "../../helper/constant";
import BedIcon from '@mui/icons-material/Bed';
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import { LBVLabel, LBVTitleLabel } from "../label/LBVLabel";
import { Card } from "shards-react";

import { isPhone } from "../../helper/constant";

import _, { replace } from 'lodash';
import { useWindowSize } from "../../helper/windowResize";
import Region from "./form/Region";
import LBVCalendar from "./form/Calendar";
import Guest from "./form/Guest";
import LBVDateInput from "../input/LBVDateInput";
import { useNavigate } from "react-router-dom";
import { setDate } from "date-fns";
import { deleteSearchObj, getSearchObj, setSearchObj } from "../../helper/localStorage";
import { calculateDays } from "../../helper/form";
import { getLocation } from "../../services/regionService";
import moment from "moment";


function BookingMain ({
    filter = null,
    info = null,
    dates = null,
    properties = false
}) {

    const [ form, setForm ] = useState({
        location: null,
        dateStart: null,
        dateEnd: null,
        guests: {
            total: 0,
            adult: 0,
            children: 0,
            rooms: 0
        }
    })

    const [ showDetail, setShowDetail ] = useState(false)
    const [ windowSize, setWindowSize ] = useState(useWindowSize())
    const [ locationList, setLocationList ] = useState([])
    const [ locationData, setLocationData ] = useState([])
    const [ query, setQuery ] = useState(null)
    const [ dateInfo, setDateInfo ] = useState(null)

    const [ showRegionList, setShowRegionList ] = useState(false)

    const navigate = useNavigate()

    const [ pages, setPages ] = useState({
        region: false,
        date: false,
        guest: false
    })

    const cardStyle = isPhone() ? {} : {
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30
    }

    const loadLocation = async () => {
        let { data, error } = await getLocation()
        if (error) { console.log(error); }
        if (data) setLocationList(data)
    }

    const filterLocation = (e) => {
        let filterData = _.filter(locationList, function(v) {
            return v.locationName.toLowerCase().indexOf(e.toLowerCase()) > -1;
        })
        if (filterData.length == 0 && e) {
            filterData.push({
                _id: e,
                locationName: e
            })
        }
        setLocationData(filterData)
    }

    const searchBtnClick = () => {
        setSearchObj({ form, dateInfo })
        if (!properties) { 
            console.log('ANJENG PEPEK')
            // if (!query) { navigate(`/properties`); return }
            navigate(`/properties?location=${query || ''}&dateStart=${dateInfo.startDate ? moment(dateInfo.startDate).format('YYYY-MM-DD') : ""}&dateEnd=${dateInfo.endDate ? moment(dateInfo.endDate).format('YYYY-MM-DD') : ""}&days=${dateInfo.days}&adult=${form.guests.adult}&children=${form.guests.children}&rooms=${form.guests.rooms}`)
        } else {
            // navigate(`/properties?location=${query || ''}&dateStart=${dateInfo.startDate ? moment(dateInfo.startDate).format('YYYY-MM-DD') : ""}&dateEnd=${dateInfo.endDate ? moment(dateInfo.endDate).format('YYYY-MM-DD') : ""}&days=${dateInfo.days}&adult=${form.guests.adult}&children=${form.guests.children}&rooms=${form.guests.rooms}`, { replace: true })
            window.location.replace(`/properties?location=${query || ''}&dateStart=${dateInfo.startDate ? moment(dateInfo.startDate).format('YYYY-MM-DD') : ""}&dateEnd=${dateInfo.endDate ? moment(dateInfo.endDate).format('YYYY-MM-DD') : ""}&days=${dateInfo.days}&adult=${form.guests.adult}&children=${form.guests.children}&rooms=${form.guests.rooms}`)
        }
    }

    const searchBtnClickPhone = () => {
        setSearchObj({ form, dateInfo })
        if (!properties) { 
            // if (!query) { navigate(`/properties`); return }
            navigate(`/properties?location=${query || ''}&dateStart=${moment(dateInfo.startDate).format('YYYY-MM-DD')}&dateEnd=${moment(dateInfo.endDate).format('YYYY-MM-DD')}&days=${dateInfo.days}&adult=${form.guests.adult}&children=${form.guests.children}&rooms=${form.guests.rooms}`)
        } else {
            window.location.replace(`/properties?location=${query || ''}&dateStart=${moment(dateInfo.startDate).format('YYYY-MM-DD')}&dateEnd=${moment(dateInfo.endDate).format('YYYY-MM-DD')}&days=${dateInfo.days}&adult=${form.guests.adult}&children=${form.guests.children}&rooms=${form.guests.rooms}`)
        }
    }

    useEffect(() => {
        loadLocation()
    }, [])

    useEffect(() => {
        console.log('dateInfo', dateInfo)
    }, [dateInfo])

    useEffect(() => {
        setLocationData(locationList)
    }, [locationList])

    useEffect(() => {
        console.log('dateInfo', filter, info, dates)
        if (filter) { setForm(filter) }
        if (info) { setQuery(info) }
        if (dates) { setDateInfo(dates) }
    }, [filter, info, dates])

    return (
        <Grid sx={{
            zIndex: 10
        }}>
            <Card style={cardStyle}>
                <Grid container p={0.5} spacing={0.5} alignItems={"center"}>
                    <Grid item container xs={12} lg={4}>
                        <Grid item container xs p={1} sx={{
                            background: '#f8fafc',
                            borderRadius: 2
                        }}>
                            <Grid container onClick={(e) => {
                                setShowDetail(true)
                                setPages(prev => (
                                    {
                                        ...prev,
                                        region: true,
                                        date: false,
                                        guest: false
                                    }
                                ))
                            }}>
                                <Grid item mt={0.5} mr={1}>
                                    <PlaceIcon sx={{
                                        color: '#957c64'
                                    }}/>
                                </Grid>
                                <Grid item xs>
                                    <LBVInput placeHolder="Where are you going ?"
                                        value={query}
                                        onChange={(e) => {
                                        setShowDetail(true)
                                        setPages(prev => (
                                            {
                                                ...prev,
                                                region: true,
                                                date: false,
                                                guest: false
                                            }
                                        ))
                                        setQuery(e.currentTarget.value)
                                        filterLocation(e.currentTarget.value)
                                    }}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            (!isPhone() && showDetail && pages.region) &&
                            <CustomPopup 
                                toggle={() => {
                                    setShowDetail(false)
                                }}
                            >
                                <Region onSelect={(value) => {
                                    setShowDetail(false)
                                    setForm(prev => ({
                                        ...prev,
                                        location: value
                                    }))
                                    setQuery(value["locationName"])
                                }} toogle={() => {}}
                                    locationData={locationData}
                                />
                            </CustomPopup>
                        }
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <LBVDateInput onClick={!isPhone() ? null : () => {
                            setShowDetail(true);
                        }} defaultDateRange={dateInfo} setDateInfo={setDateInfo} />
                    </Grid>
                    <Grid item container xs={12} lg={3}>
                        <Grid item p={1} xs sx={{
                            background: '#f8fafc',
                            borderRadius: 2
                        }} onClick={() => {
                            setShowDetail(true)
                            setPages(prev => (
                                {
                                    ...prev,
                                    region: false,
                                    date: false,
                                    guest: true
                                }
                            ))
                        }}>
                            <Grid container>
                                <Grid item mt={0.5} mr={1}>
                                    <PersonIcon sx={{
                                        color: '#957c64'
                                    }}/>
                                </Grid>
                                <Grid item xs>
                                    <LBVInput value={form.guests.adult ? `${form.guests.adult} Adult • ${form.guests.children || 0} Child • ${form.guests.rooms || 0} Room${form.guests.rooms > 1 ? 's' : ''}` : null} placeHolder="Guest(s)" />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            (!isPhone() && showDetail && pages.guest) &&
                            <CustomPopup
                                toggle={() => {
                                    setShowDetail(false)
                                }}
                            >
                                <Guest 
                                    onChangeGuest={() => {}}
                                    onSave={(e) => {
                                        setShowDetail(false)
                                        console.log('here check e', e)
                                        setForm(prev => ({
                                            ...prev,
                                            guests: e
                                        }))
                                    }} />
                            </CustomPopup>
                        }
                    </Grid>
                    {
                        !isPhone() &&
                        <Grid item xs={12} lg={1} textAlign={"right"}>
                            <Button variant="contained" style={ isPhone() ? {
                                width: '100%',
                                minHeight: 50,
                                borderRadius: 10
                            } : {
                                width: '100%',
                                minHeight: 50,
                                borderRadius: 50,
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10
                            }}
                                onClick={() => {
                                    searchBtnClick()
                                }}
                            >
                                <LBVLabel inheritColor>Search</LBVLabel>
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Card>
            {
                (isPhone() && showDetail) &&
                <Dialog open={showDetail} 
                    onClose={() => {
                        setShowDetail(false)
                }}>
                    <Grid container>
                        <Grid item xs={12} p={2}>
                            <LBVTitleLabel>{"Where are you going"}</LBVTitleLabel>
                            <LBVInput label={""} onChange={(e) => {
                                setShowRegionList(true)
                                setQuery(e.currentTarget.value)
                                filterLocation(e.currentTarget.value)
                            }} value={query}/>
                            {
                                showRegionList && 
                                <Region onSelect={(value) => {
                                    setForm(prev => ({
                                        ...prev,
                                        location: value
                                    }))
                                    setQuery(value["locationName"])
                                    setShowRegionList(false)
                                }} toogle={() => {}} locationData={locationData} />
                            }
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <LBVCalendar 
                                price={null}
                                vertical={true}
                                month={1}
                                onChange={(e) => {
                                    console.log('here date info - change', e)
                                    let date = e.length > 0 ? e[0] : null
                                    console.log('here date info - change', date)
                                    if (date) {
                                        let totalDays = calculateDays({
                                            startDate: date.startDate,
                                            endDate: date.endDate
                                        })
                                        setDateInfo((prev) => ({
                                            ...prev,
                                            startDate: date.startDate,
                                            endDate: date.endDate,
                                            days: totalDays,
                                            totalDays: totalDays.length
                                        }))
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} p={2}>
                            <Guest 
                                onChangeGuest={(e) => {
                                    console.log('here check e mobile', e)
                                    setForm(prev => ({
                                        ...prev,
                                        guests: e
                                    }))
                                }}
                                onSave={() => {}} />
                        </Grid>
                        <Grid container item xs={12} justifyContent={"center"} p={2} sx={{
                            backgroundColor: 'white',
                            position: 'sticky',
                            bottom: 0
                        }}>
                            <Grid item xs={6}>
                                <Button variant="contained" style={ isPhone() ? {
                                    width: '100%',
                                    minHeight: 50,
                                    borderRadius: 10
                                } : {
                                    width: '100%',
                                    minHeight: 50,
                                    borderRadius: 50,
                                    borderTopLeftRadius: 10,
                                    borderBottomLeftRadius: 10
                                }}
                                    onClick={() => {
                                        searchBtnClickPhone()
                                    }}
                                >
                                    <LBVLabel inheritColor>Search</LBVLabel>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            }
        </Grid>
    )
}

function CustomPopup ({
    toggle,
    children
}) {

    return (
        <Grid p={2} sx={{
            position: 'absolute',
            marginTop: 6,
            zIndex: 100
        }}>
            <div style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            }} onClick={toggle}/>
            <Card style={{
                padding: 10
            }}>
            { children }
            </Card>
        </Grid>
    )

}

export default BookingMain