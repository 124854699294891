import React, { useEffect, useState } from "react";

import { DateRangePicker } from 'react-date-range';
import { format } from "date-fns";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { convertPrice } from "../../../helper/priceFunction";
import moment from "moment";

function LBVCalendar ({
    onChange, vertical = false, price, month = 2
}) {

    const [ orientation, setOrientation ] = useState("horizontal")

    const [calendarState, setCalendarState] = useState([{
        startDate: new Date(),
        endDate: new Date(moment(new Date()).add(1, 'day')),
        key: 'selection'
    }]);

    useEffect(() => {
        if (vertical) { setOrientation("vertical") }
    }, [vertical])

    useEffect(() => {
        if (moment(calendarState[0].startDate).format('YYYY-MM-DD') === moment(calendarState[0].endDate).format('YYYY-MM-DD')) { return; }
        onChange(calendarState)
    }, [calendarState])

    const onChangeCalendarSelection = (e) => {
        setCalendarState([e.selection])
    }

    function customDayContent(day) {
        let priceInfo = null;
        if (price) {
            let priceValue = 0
            for (let i = 0; i < price.length; i ++) {
                if (!price[i].date && !price[i].day) {
                    priceValue = price[i].price
                }
                if (price[i].day && price[i].day.indexOf(format(day, "eeee")) >= 0) {
                    priceValue = price[i].price
                }
                if (price[i].date && price[i].date.indexOf(format(day, "yyyy-MM-dd")) >= 0) {
                    priceValue = price[i].price
                }
            }
            priceInfo = (
                <div
                    className="rdrPriceInfo"
                    style={{
                        height: '10px',
                        // width: "5px",
                        borderRadius: "100%",
                        // background: "orange",
                        position: "absolute",
                        top: 14,
                        right: 0,
                        left: 0,
                        fontSize: 10,
                        textAlign: 'center',
                        color: '#a2a2a2'
                    }}
                >{convertPrice(priceValue)}</div>
            )
        }
        return (
          <div>
            <span>{format(day, "d")}</span>
            {priceInfo}
          </div>
        )
    }

    return (
        <DateRangePicker
            minDate={new Date()}
            months={month}
            showPreview={false}
            showSelectionPreview={false}
            ranges={calendarState}
            onChange={onChangeCalendarSelection}
            direction={orientation}
            dayContentRenderer={customDayContent}
        />
    )
}

export default LBVCalendar